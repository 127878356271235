<template>
  <client-only>
    <v-btn
        v-if="completed"
        variant="tonal"
        @click="$emit('retry', $event)"
        :append-icon="retryIcon"
    >
      <slot :completed="completed">
        Send new code
      </slot>
    </v-btn>
    <v-btn v-else
           :disabled="!completed"
           variant="outlined"
    >
      <slot
          name="timer"
          :time-until-seconds="secondsRemaining"
          :completed="completed"
      >
        Retry in {{ secondsRemaining }}s...
      </slot>
    </v-btn>

  </client-only>
</template>
<script lang="ts" setup>
interface Props {
  ttl?: number;
  retryIcon?: string
}

defineEmits(['retry'])

const props = withDefaults(defineProps<Props>(), {
  ttl: 90,
  retryIcon: 'mdi mdi-again'
});


const timeUntilSeconds = ref<number>(0);

let loop: NodeJS.Timeout | undefined;

const ticker = () => {
  if (timeUntilSeconds.value === 0) {
    clearInterval(loop);
  }
  timeUntilSeconds.value--;
};
const startLoop = () => {
  if (loop) {
    clearInterval(loop);
  }
  timeUntilSeconds.value = props.ttl;

  ticker()
  loop = setInterval(ticker, 1000);
}

const completed = computed(() => timeUntilSeconds.value <= 0);

const secondsRemaining = computed(() => {
  return timeUntilSeconds.value;

  // return props.ttl;
});


onMounted(startLoop)
watch(toRef(props.ttl), startLoop);

defineExpose({
  restart: startLoop,
});

</script>